$('.main_price_new_vik').addClass("scroll-table3");
//$('.main_price_new_vik').parent().insertBefore('div').addClass("table_margin"); 
$('.priceTable table').addClass("scroll-table");
$('.priceTableMarka table').addClass("scroll-table");
$('.priceTableMarka table').addClass("scroll-table");
var url_patch = '';
var list_comtrans_url = [];
function myfunc2(arg2) {
    if (arg2 == 1) {
        document.getElementById("title2").innerHTML = " Получить консультацию";
        document.getElementById('tip_zaiavki').value = " Получить консультацию ";
    } else if (arg2 == 2) {
        document.getElementById("title2").innerHTML = " Заявка на эвакуатор ";
        document.getElementById('tip_zaiavki').value = " Заявка на эвакуатор ";
    } else if (arg2 == 3) {
        document.getElementById("title2").innerHTML = " Заявка на диагностику ";
        document.getElementById('tip_zaiavki').value = " Заявка на диагностику ";
    }  else if (arg2 == 4) {
        document.getElementById("title2").innerHTML = " Записаться на ремонт ";
        document.getElementById('tip_zaiavki').value = " Записаться на ремонт ";
    }
}

var url = window.location.href;
var arr = url.split('/');
if (arr[3] == 'about') {
    doc_w = document.body.clientWidth;
    if (doc_w <= 356) {
        $('.svg-icon--logo-wh').hide();
        $('.app-header__middle-line-inner').css("padding-top", "10px");

    }
}

$(document).ready(function () {
    var url = window.location.href;
    var arr = url.split('/');

    var marka = ['acura', 'daihatsu', 'honda', 'hyundai', 'infiniti', 'isuzu', 'kia', 'lexus', 'mazda', 'mitsubishi', 'nissan', 'subaru', 'suzuki', 'toyota'];

    if (marka.indexOf(arr[3]) >= 0)
    {
        var imgName = 'url(/images/static/bckg/' + arr[3] + '.jpg) 50% 0 no-repeat'
        $('.app-wrapper.app-wrapper--services-detailed .app-header .app-header__bg').css('background', imgName);
    }

    if (marka.indexOf(arr[4]) >= 0 && arr[3] == 'parts') {
        var imgName = 'url(/images/static/bckg/' + arr[4] + '.jpg) 50% 0 no-repeat'
        $('.app-wrapper.app-wrapper--services-detailed .app-header .app-header__bg').css('background', imgName);
    }
    
    // content collapse
    if ($("div").is(".content__colapse")) {
        $("#content_readmore").on("click", function () {
            $(".content__colapse").addClass("content__uncolapse");
            $(".content__gradient").css("display", "none");
            $(this).css("display", "none");
        });
    }
    test();
});

function test(){
    url_patch = document.location.pathname;
    list_comtrans_url = [
    '/commercial/',
    '/toyota/town_ace/',
    '/toyota/alphard/',
    '/nissan/serena/',
    '/nissan/vanette/',
    '/honda/odyssey/',
    '/subaru/libero/',
    '/subaru/sambar/',
    '/daihatsu/daihatsu_hijet/',
    '/hyundai/h_starex/',
    '/hyundai/hyundai_hd/',
    '/hyundai/porter/',
    '/hyundai/h1/',
    '/kia/carnival/',
    '/honda/stepwgn/',
    '/honda/elysion/',
    '/nissan/elgrand/'];

    if(url_patch && list_comtrans_url.includes(url_patch)){
        $('.m-call-tracking-widget').remove();
        $('#commercial_hide').remove();
    }
}

$('#arrowShow').click(function (e) {
    e.preventDefault();
    $('.allPoints').fadeIn("slow");
    //$('.allPoints').css('display', 'contents');
    $('#arrowShow').hide();
    $('#arrowHide').show();

});

$('#arrowHide').click(function (e) {
    e.preventDefault();
    $('.allPoints').fadeOut("slow");
    //$('.allPoints').css('display', 'none');
    $('#arrowShow').show();
    $('#arrowHide').hide();

    $('html, body').animate({scrollTop: $('#arrowShow').position().top}, 'slow');
});

$('.model_name').click(function () {
    $(this).parent().next('.childModel').slideToggle();
});

$(document).ready(function () {

    "use strict";

    var month = ["январе", "феврале", "марте", "апреле", "мае", "июне", "июле", "августе", "сентябре", "октябре", "ноябре", "декабре"];

    var date = new Date();
    $('#currentMonth').text(month[date.getMonth()]);

    /**
     * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
     * Она сработает только один раз через N миллисекунд после последнего вызова.
     * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
     * первого запуска.
     */

    function debounce(func, wait, immediate) {

        var timeout;

        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate)
                    func.apply(context, args);
            };

            var callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);

            if (callNow)
                func.apply(context, args);
        };
    }
    ;

    /**
     * определение версии IE 10 или 11
     *
     * @returns {Number} 10, 11, или 0 если это не IE
     */
    function GetIEVersion() {

        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");

        // If IE, return version number.
        if (Idx > 0) {
            return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
        }

        // If IE 11 then look for Updated user agent string.
        else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            return 11;
        } else {
            return 0; //It is not IE
        }
    }


// Test via a getter in the options object to see if the passive property is accessed
    /*window.supportsPassive = false;
     
     try {
     
     var opts = Object.defineProperty({}, 'passive', {
     get: function() {
     window.supportsPassive = true;
     }
     });
     
     window.addEventListener("test", null, opts);
     } catch (e) {}*/


    /**
     * генерация HTML кода для svg-иконки
     */
    function getMarmeladIconHTML(name, tag, attrs) {

        if (typeof name === 'undefined') {
            console.error('name is required');
            return false;
        }

        if (typeof tag === 'undefined') {
            tag = 'div';
        }

        var classes = 'svg-icon svg-icon--<%= name %>';

        var iconHTML = [
            '<<%= tag %> <%= classes %>>',
            '<svg class="svg-icon__link">',
            '<use xmlns:xlink="https://www.w3.org/1999/xlink" xlink:href="#<%= name %>"></use>',
            '</svg>',
            '</<%= tag %>>'
        ]
                .join('')
                .replace(/<%= classes %>/g, 'class="' + classes + '"')
                .replace(/<%= tag %>/g, tag)
                .replace(/<%= name %>/g, name);

        return iconHTML;

    }

    /**
     * определение существования элемента на странице
     */
    $.exists = function (selector) {
        return ($(selector).length > 0);
    }

    var advantagesOwl = $('.advantages__slider').addClass('owl-carousel');
    advantagesOwl.owlCarousel({
        items: 3,
        smartSpeed: 1000,
        //center: true,
        nav: true,
        navText : ["<i class='fa fa-lg fa-chevron-left'></i>","<i class='fa fa-lg fa-chevron-right'></i>"],
        lazyLoad: true,
        loop: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                center: false
            },
            481: {
                items: 2,
                center: false
            },
            640: {
                items: 2,
                center: false
            },
            768: {
                items: 2,
                center: false
            },
            1000: {
                items: 2,
                center: false
            },
            1001: {
                items: 4
            }
        }
    });


    if ($.exists('.app__block')) {
        var scene = document.getElementById('relaxer');
        var parallax = new Parallax(scene);
        var scene2 = document.getElementById('relaxer-2');
        var parallax2 = new Parallax(scene2);
    }
    $('.app-form__select select').select2();

    $('.app-header__search-button').on('click', function () {
        $(this).toggleClass('active');
    });
    /* linksHover */
    function linksHover() {
        if ($('.effect-block').length) {
            $('.effect-block a').hover(
                    function () {
                        $(this).parents('.effect-block:eq(0)').addClass('effect-block-active');
                    },
                    function () {
                        $(this).parents('.effect-block:eq(0)').removeClass('effect-block-active');
                    });
        }
    }
    linksHover();
    var maxHeightScroll = $('.app-header__middle-line').offset().top;
    $(window).on('scroll', (function () {
        if (window.matchMedia("(min-width: 1025px)").matches) {
            var old_scroll = $(window).scrollTop();
            if (old_scroll >= maxHeightScroll) {
                $(".app-header__middle-line").addClass('scroller');
            } else {
                $(".app-header__middle-line").removeClass('scroller');
            }
        }
    }));
    inView('.main-description__inner').once('enter', function () {
        $('.app-header__line').addClass('animate');
    });
    $(window).on('load', function () {
        AOS.init({
            disable: function () {
                var maxWidth = 1025;
                return window.innerWidth < maxWidth;
            }
        });
    });

    var slideCount = $('.count-slider__item').length;
    var $this = $(this);
    $('.count-slider__block').addClass('owl-carousel').owlCarousel({
        items: 1,
        nav: true,
        dots: true,
        dotsEach: 1,
        dotsData: true,
        smartSpeed: 1000,
        onInitialized: function (event) {
            $this.find('.owl-dots .owl-dot').each(function (index) {
                var number = index + 1;
                $(this).empty().append('<span class="red">' + number + '</span> <span class="gray">из  </span><span class="black">' + slideCount + '</span>');
            });
        }
    });

    var doc_w = document.body.clientWidth;
    console.log('doc width => ' + doc_w);
    if (doc_w < 500)
    {
        $('.double-slider').addClass('owl-carousel').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 2000
        });

    } else {
        $('.double-slider').addClass('owl-carousel').owlCarousel({
            items: 2,
            loop: true,
            dots: true,
            lazyLoad: true,
            smartSpeed: 2000,
            autoplay: true,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                900: {
                    items: 1
                },
                1024: {
                    items: 1
                },
                1025: {
                    items: 2
                }
            }

        });
    }

// Test via a getter in the options object to see if the passive property is accessed
    var supportsPassive = false;

    try {
        var opts = Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassive = true;
            }
        });
        window.addEventListener("test", null, opts);
    } catch (e) {
    }
// Use our detect's results. passive applied if supported, capture will be false either way.
// elem.addEventListener('touchstart', fn, supportsPassive ? { passive: true } : false);

// https://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
    window.requestAnimFrame = (function () {
        return  window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
    })();
    /**
     * Simple menu
     * @version 1.0.0-beta.1
     */
    ;
    (function ($) {

        $.fn.simpleMenu = function (options) {

            var settings = $.extend(true, {
                timing: 300,
                topMargin: 0,
                menu: {
                    list: 'ul',
                    item: 'li',
                    trigger: 'a'
                },
                classes: {
                    opened: 'opened',
                    active: 'active',
                    used: 'used'
                },
                attrs: {
                    opened: {
                        key: 'opened',
                        true: 'true',
                        false: 'false'
                    }
                }
            }, options);

            var $this = this;
            var $trigers = $this.find(settings.menu.list).parent(settings.menu.item).find('> ' + settings.menu.trigger);

            $trigers.on('click', function (event) {

                event.preventDefault();

                var $list = $(this).parent(settings.menu.item).find('> ' + settings.menu.list);

                $list.css({
                    display: 'block',
                });

                if ($list.parent(settings.menu.item).hasClass(settings.classes.opened)) {

                    $list.stop().animate({
                        marginTop: -($list.outerHeight(true) - settings.topMargin)
                    }, settings.timing, function () {
                        $list
                                .attr(settings.attrs.opened.key, settings.attrs.opened.false)
                                .addClass(settings.classes.used)
                                .parent(settings.menu.item).removeClass(settings.classes.opened);
                    });

                } else {

                    if (!$list.hasClass(settings.classes.used)) {
                        $list
                                .css({
                                    marginTop: -($list.outerHeight(true) - settings.topMargin)
                                })
                                .addClass(settings.classes.used);
                    }

                    $list
                            .parent(settings.menu.item).addClass('opening')
                            .end()
                            .stop().animate({
                        marginTop: (0 + settings.topMargin)
                    }, settings.timing, function () {
                        $list
                                .attr(settings.attrs.opened.key, settings.attrs.opened.true)
                                .parent(settings.menu.item).removeClass('opening')
                                .end()
                                .addClass(settings.classes.used)
                                .parent(settings.menu.item).addClass(settings.classes.opened);
                    });
                }

            });

        };

    })(jQuery);

    var mobileHeader = $('.mobile-header');
    
    var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    var event = "click";

    if(iOS != null) {
        event = "touchstart";
    }
    
    

// внутренние классы
    var mobile_classes = {
        opened: 'is-opened',
        closed: 'is-closed'
    };

    var target_toolbar_buttons = $('.me-schedule-trigger, .me-share-trigger, .me-search-trigger, .me-metro-trigger, .me-feedback-trigger');

// выпадалки
    var toolbar_button_dropdowns = $('.toolbar-button-dropdown');

    /**
     * закрываем все выпадалки
     */
    function close_toolbar_button_dropdowns() {
        toolbar_button_dropdowns
                .removeClass(mobile_classes.opened);
        target_toolbar_buttons
                .removeClass(mobile_classes.opened);        
    }

    /**
     * предотвращаем всплытие кликов на документе от .toolbar-button-dropdown
     */
    toolbar_button_dropdowns.on(event, function (event) {
        event.stopPropagation();
    });

    var menu_done = false;
    
    function menuDoneReset() {
        menu_done = false;
    }

    /**
     * кнопки у которых есть выпадалки
     */
    target_toolbar_buttons.on(event, function (event) {
        console.log(event);
        event.stopPropagation();
        event.preventDefault();

        var $self = $(this);

        if (!menu_done) {
            if ($self.hasClass(mobile_classes.opened)) {

                $self.removeClass(mobile_classes.opened);
                close_toolbar_button_dropdowns();

            } else {

                close_toolbar_button_dropdowns();

                if ($self.hasClass('me-search-trigger')) {
                    $('.mobile-search-form__input').focus();
                }

                $self.addClass(mobile_classes.opened);
            }
            menu_done = true;
            setTimeout(menuDoneReset, 1500);
        }
                        

    });

    /**
     * нажатие на ESC
     */
    $(document).on('keydown', function (event) {
        if (event.keyCode === 27) {
            close_toolbar_button_dropdowns();
        }
        console.log(event);
    });

    /*!
     * клик по документу
     */
    $(document).on('click', function (event) {
        close_toolbar_button_dropdowns();
        console.log(event);
    });

    /*!
     * Remodal actions
     */

// расширенный поиск

    var extendedSearchWindow = $('[data-remodal-id=extendedSearch]').remodal();

    $('.mobile-search-form__ext-search').on('click', function (event) {
        event.preventDefault();
        extendedSearchWindow.open();
    });

    $('.remodal').find('.search-btn').removeClass('search-btn').addClass('shop2-btn');

    /**
     * Таблицы со скроллом
     */
    $('.scroll-table, .shop2-table-order:not(.shop2-table-order--summary)').wrap("<div class='scroll-table_enabled'/>");
    $('.scroll-table3').wrap("<div class='scroll-table_enabled3'/>");

    /**
     * Мобильное меню сайта
     */
    var asideMenuBtn = $('.b-aside-menu-btn');
    var asideMenu = $('.b-aside-menu');
    var asideHead = $('.b-aside-menu__head');
    var asideMenuContent = $('.b-aside-menu__content');
    var asideMenuScroller = $('.b-aside-menu__scroller-content');
    var asideMenuFoot = $('.b-aside-menu__foot');

    function openAsideMenu() {
        asideMenu.addClass('js-animate js-opening');
    }

    function closeAsideMenu() {

        asideMenu.removeClass('js-animate');

        setTimeout(function () {
            asideMenu.removeClass('js-opening');
        }, 150);
    }

    /*var pxScroller = document.querySelector('.b-aside-menu__scroller');
     
     function pxAsideHead(event) {
     asideHead.css({
     transform : 'translateY(' + event.target.scrollTop / 1.8 + 'px)'
     });
     }
     
     pxScroller.addEventListener('scroll', pxAsideHead, supportsPassive ? { passive: true } : false);
     */

    asideMenuBtn.on('pointerup', function (event) {
        event.preventDefault();
        openAsideMenu();
    });

    $('.b-aside-menu__close').on('pointerup', function (event) {
        event.preventDefault();
        closeAsideMenu();
        $("#brand_mobile_link_list").slideUp();
        $("#service_mobile_link_list").slideUp();
    });

    $('.b-aside-menu__overlay').on('pointerup', function (event) {
        event.preventDefault();
        closeAsideMenu();
    });

    asideMenuScroller.find(".head_dropdown_button.brand").on('click', function() {
        $("#brand_mobile_link_list").slideToggle();
    });
    
    asideMenuScroller.find(".head_dropdown_button.service").on('click', function() {
        $("#service_mobile_link_list").slideToggle();
    });
    
    if ($.exists('.app-header__second-menu')) {

        var newSecondNav = $('.app-header__second-menu').clone();

        newSecondNav
                .removeClass('app-header__second-menu')
                .addClass('aside-nav-list aside-nav-list_app-header__second-menu')
                .appendTo(asideMenuScroller);
    }

    $.each(asideMenuScroller.find('li'), function (index, element) {

        if ($(element).find('ul').length) {

            var triggerIcon = ['<div class="svg-icon svg-icon--angle-down">',
                '<svg class="svg-icon__link" fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="https://www.w3.org/2000/svg">',
                '<path d="M7 10l5 5 5-5z"/> <path d="M0 0h24v24H0z" fill="none"/>',
                '</svg>',
                '</div>'].join('');

            var subMenuTrigger = $('<div class="sub-menu-trigger">' + triggerIcon + '</div>');

            $(element)
                    .addClass('is-has-child')
                    .append(subMenuTrigger);
        }
    });
    if ($.exists('.b-aside-menu')) {
        /*$('.sub-menu-trigger').on('click', function(){
         $(this).parent().toggleClass('active');
         $(this).toggleClass('active');
         });*/
        $('.aside-nav-list').simpleMenu({
            timing: 500,
            menu: {
                trigger: '.sub-menu-trigger'
            }
        });
    }
    ;
    $(function () {
        /**
         * Прокрутка страницы вверх
         */
        // main function
        function scrollToY(scrollTargetY, speed, easing) {
            // scrollTargetY: the target scrollY property of the window
            // speed: time in pixels per second
            // easing: easing equation to use

            var scrollY = window.scrollY || document.documentElement.scrollTop,
                    scrollTargetY = scrollTargetY || 0,
                    speed = speed || 2000,
                    easing = easing || 'easeOutSine',
                    currentTime = 0;

            // min time .1, max time .8 seconds
            var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

            // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
            var easingEquations = {
                easeOutSine: function (pos) {
                    return Math.sin(pos * (Math.PI / 2));
                },
                easeInOutSine: function (pos) {
                    return (-0.5 * (Math.cos(Math.PI * pos) - 1));
                },
                easeInOutQuint: function (pos) {
                    if ((pos /= 0.5) < 1) {
                        return 0.5 * Math.pow(pos, 5);
                    }
                    return 0.5 * (Math.pow((pos - 2), 5) + 2);
                }
            };
            // add animation loop
            function tick() {
                currentTime += 1 / 60;

                var p = currentTime / time;
                var t = easingEquations[easing](p);

                if (p < 1) {
                    requestAnimFrame(tick);

                    window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
                } else {
                    //console.log('scroll done');
                    window.scrollTo(0, scrollTargetY);
                }
            }

            // call it once to get started
            tick();
        }

        /** ===========================================================================
         * Mobile scripts
         * ============================================================================ */
        /**
         * определение существования элемента на странице
         */
        $.exists = function (selector) {
            return ($(selector).length > 0);
        };
        var toTop = document.getElementById('b-2top');

        toTop.addEventListener('pointerup', function () {
            scrollToY(0, 1500, 'easeInOutQuint');
        }, false);

        function setupScrollEvents() {

            if (window.scrollY > 30) {
                mobileHeader.addClass('mobile-header--not-top');
            } else {
                mobileHeader.removeClass('mobile-header--not-top');
            }

            if (window.scrollY > 200) {
                toTop.classList.add('b-2top--is-show');
            } else {
                toTop.classList.remove('b-2top--is-show');
            }
        }

        setupScrollEvents();

        window.addEventListener('scroll', setupScrollEvents, supportsPassive ? {passive: true} : false);
    });

    var linksFlag = false;
    $(window).on('resize', function () {
        if (window.matchMedia("(max-width: 600px)").matches) {
            if (!linksFlag) {
                $('.links__items').hide();
                $('.links__title').on('click', function () {
                    if (window.matchMedia("(max-width: 600px)").matches) {
                        $(this).toggleClass('active').siblings('.links__items').stop().slideToggle();
                    }
                });

                linksFlag = true;
            }
        } else {
            if (linksFlag) {
                $('.links__items').show();

                linksFlag = false;
            }
        }
    }).trigger('resize');

    $('.our-works-slider__inner').addClass('owl-carousel').owlCarousel({
        items: 2,
        nav: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 1
            },
            1025: {
                items: 2
            }
        }
    });


    inView('.photo-block__add-button').once('enter', function () {
        $('.photo-block__add-button button').addClass('animate');
        $('.photo-block__add-button button .camera').addClass('animate');
    });

    $(document).on('click', '#bKaluzCall', function () {
        $("#serviceAddressCall option:selected").removeAttr("selected");
        $('#serviceAddressCall option[value=1]').attr('selected', 'selected');
    });

    $(document).on('click', '#bNigegrCall', function () {
        $("#serviceAddressCall option:selected").removeAttr("selected");
        $('#serviceAddressCall option[value=2]').attr('selected', 'selected');
    });

    $(document).on('click', '#bSev', function () {
        $("#serviceAddressCall option:selected").removeAttr("selected");
        $('#serviceAddressCall option[value=4]').attr('selected', 'selected');
    });
	
	$(document).on('click', '#bLobnensCall', function () {
        $("#serviceAddressCall option:selected").removeAttr("selected");
        $('#serviceAddressCall option[value=3]').attr('selected', 'selected');
    });

//evacuation

    $(document).on('click', '#bKaluzEvac', function () {
        $("#serviceAddressCallEvac option:selected").removeAttr("selected");
        $('#serviceAddressCallEvac option[value=1]').attr('selected', 'selected');
    });

    $(document).on('click', '#bNigegrEvac', function () {
        $("#serviceAddressCallEvac option:selected").removeAttr("selected");
        $('#serviceAddressCallEvac option[value=2]').attr('selected', 'selected');
    });

    $(document).on('click', '#bLobnensEvac', function () {
        $("#serviceAddressCallEvac option:selected").removeAttr("selected");
        $('#serviceAddressCallEvac option[value=3]').attr('selected', 'selected');
    });
	
	$(document).on('click', '#bSev', function () {
        $("#serviceAddressCallEvac option:selected").removeAttr("selected");
        $('#serviceAddressCallEvac option[value=4]').attr('selected', 'selected');
    });

//popup
    $('#bKaluzCallPopup').click(function () {
        $('#serviceAddressCall option[value=1]').attr('selected', 'selected');
    });
    $('#bNigegrCallPopup').click(function () {
        $('#serviceAddressCall option[value=2]').attr('selected', 'selected');
    });
    $('#bLobnensCallPopup').click(function () {
        $('#serviceAddressCall option[value=3]').attr('selected', 'selected');
    });
	$('#bSevCallPopup').click(function () {
        $('#serviceAddressCall option[value=3]').attr('selected', 'selected');
    });

    var popup = $('[data-remodal-id=popup-form]').remodal();
    var popup2 = $('[data-remodal-id=popup-form2]').remodal();
    var popup3 = $('[data-remodal-id=popup-form3]').remodal();
    var popup4 = $('[data-remodal-id=popup-form4]').remodal();
    var popup5 = $('[data-remodal-id=popup-form5]').remodal();
    var popup6 = $('[data-remodal-id=popup-form6]').remodal();

    $('.popup-call').on('click', function (e) {
        e.preventDefault();
        popup.open();
    });

    $('.popup-call_top').on('click', function (e) {
        e.preventDefault();
        popup2.open();
    });


    $('.popup-call_evac').on('click', function (e) {
        e.preventDefault();
        popup3.open();
    });

    $('.popup-call_otziv').on('click', function (e) {
        e.preventDefault();
        popup4.open();
    });

    $('.popup-pay').on('click', function (e) {
        e.preventDefault();
        popup5.open();
    });

    $('.popup-report').on('click', function (e) {
        e.preventDefault();
        popup6.open();
    });

    $('.reviews__slider').addClass('owl-carousel').owlCarousel({
        items: 3,
        center: true,
        nav: true,
        loop: true,
        responsive: {
            0: {
                items: 1,
                center: false,
                autoplay: true
            },
            510: {
                items: 1,
                center: false
            },
            768: {
                items: 1,
                center: false
            },
            769: {
                items: 3,
                center: true
            }
        }
    });


    $('.links__item-text').on('click', function (e) {
        if ($(this).next('.services-choose__hidden-block').is(':visible')) {
            $(this).next('.services-choose__hidden-block').hide();
        } else {
            $(this).next('.services-choose__hidden-block').show();
        }
    });


    $('.icon_arrow').on('click', function (e) {
        if ($(this).parent().children('.services-choose__hidden-block').is(':visible')) {
            $(this).parent().children('.services-choose__hidden-block').hide();
        } else {
            $(this).parent().children('.services-choose__hidden-block').show();
        }
    });

    $('.slider__inner').addClass('owl-carousel').owlCarousel({
        items: 1,
        autoplay: true,
        smartSpeed: 3000,
        mouseDrag: false
    });
    $('.special-slider__block').addClass('owl-carousel').owlCarousel({
        items: 3,
        margin: 37,
        lazyLoad: true,
        nav: true,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            640: {
                items: 2,
                margin: 10
            },
            1024: {
                margin: 10
            },
            1200: {
                margin: 10
            },
            1201: {
                margin: 37
            }
        }
    });
    $('.staff__slider').addClass('owl-carousel').owlCarousel({
        items: 4,
        nav: true,
        loop: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1025: {
                items: 4
            }
        }
    });
    $('.step-slider__block-inner').addClass('owl-carousel').owlCarousel({
        items: 7,
        nav: true,
        lazyLoad: true,
        //loop: true,
        responsive: {
            0: {
                items: 1
            },
            380: {
                items: 1
            },
            530: {
                items: 2
            },
            700: {
                items: 3
            },
            900: {
                items: 4
            },
            1100: {
                items: 5
            },
            1200: {
                items: 6
            },
            1201: {
                items: 7
            }
        }
    });

    (function ($, window) {
        $.fn.myTabs = function () {
            return this.each(function () {
                var $this = $(this),
                        tabsLinks = $this.find('.tabs__links .tabs__link'),
                        tabsContents = $this.find('.tabs__blocks'),
                        tabsBlocks = $this.find('.tabs__blocks .tabs__block'),
                        activeClass = 'active',
                        flag = false;

                $('.tabs__next').on('click', function (e) {
                    var lastNumber = tabsLinks.length;
                    var transform = $('.tabs__links-transform').css('transform').split(',');
                    transform = parseInt(transform[4]);
                    var activeTitle = $('.tabs__link.active');
                    var targetId = activeTitle.data('target-id');
                    var next = activeTitle.next();

                    if (next.hasClass('inactive')) {
                        var transformWidth = next.outerWidth();
                        $('.tabs__links-transform').css('transform', 'translateX(' + (transform + -transformWidth) + 'px)');
                        next.removeClass('inactive');
                        setTimeout(function () {
                            tabsLinks.each(function () {
                                var coords = $(this).offset().left;
                                if (coords < 0) {
                                    $(this).addClass('inactive');
                                }
                            });
                        }, 550);

                    }

                    if (targetId == lastNumber) {
                        $('.tabs__links-transform').css('transform', 'translateX(0)');
                        setTimeout(function () {
                            tabsLinks.removeClass('inactive');
                            var maxWidth = $('.tabs__links').offset().left + $('.tabs__links').width();
                            tabsLinks.each(function (index) {
                                var position = $(this).offset().left;
                                if (position >= maxWidth) {
                                    $(this).addClass('inactive');
                                }
                                if (index == 0) {
                                    var targetId = $(this).addClass('active').data('target-id');
                                    tabsBlocks.each(function () {
                                        var tabsTargetId = $(this).data('target-id');
                                        if (targetId == tabsTargetId) {
                                            $(this).addClass('active');
                                        }
                                    });
                                }
                            });
                        }, 550);
                    }
                    var nextTargetId = activeTitle.removeClass('active').next().addClass('active').data('target-id');
                    tabsBlocks.removeClass('active');
                    tabsBlocks.each(function () {
                        var tabsTargetId = $(this).data('target-id');
                        if (nextTargetId == tabsTargetId) {
                            $(this).addClass('active');
                        }
                    });
                });

                $('.tabs__prev').on('click', function (e) {
                    var transform = $('.tabs__links-transform').css('transform').split(',');
                    transform = parseInt(transform[4]);
                    var activeTitle = $('.tabs__link.active');
                    var targetId = activeTitle.data('target-id');
                    var prev = activeTitle.prev();
                    if (prev.hasClass('inactive')) {
                        var transformWidth = prev.outerWidth();
                        $('.tabs__links-transform').css('transform', 'translateX(' + (transform + transformWidth) + 'px)');
                        prev.removeClass('inactive');
                        var maxWidth = $('.tabs__links').offset().left + $('.tabs__links').width();
                        setTimeout(function () {
                            tabsLinks.each(function () {
                                var coords = $(this).offset().left;
                                if (coords >= maxWidth) {
                                    $(this).addClass('inactive');
                                }
                            });
                        }, 550);
                    }
                    if (targetId == 1) {
                        var transformWidth = 0;
                        var titleLength = tabsLinks.length;
                        $('.tabs__link.inactive').each(function () {
                            transformWidth = transformWidth + $(this).outerWidth();
                        });
                        $('.tabs__links-transform').css('transform', 'translateX(' + -transformWidth + 'px)');
                        setTimeout(function () {
                            tabsLinks.each(function () {
                                var linkTargetId = $(this).data('target-id');
                                $(this).removeClass('inactive');
                                var position = $(this).position().left;
                                if (position < 0) {
                                    $(this).addClass('inactive');
                                }
                                if (linkTargetId == titleLength) {
                                    $(this).addClass('active');
                                    tabsBlocks.each(function () {
                                        if ($(this).data('target-id') == titleLength) {
                                            $(this).addClass('active');
                                        }
                                    });
                                }
                            });
                        }, 550);

                    }
                    var prevTargetId = activeTitle.removeClass('active').prev().addClass('active').data('target-id');
                    tabsBlocks.removeClass('active');
                    tabsBlocks.each(function () {
                        var tabsTargetId = $(this).data('target-id');
                        if (prevTargetId == tabsTargetId) {
                            $(this).addClass('active');
                        }
                    });
                });

                tabsLinks.on('click.myTabs', onLinkClick);


                function onLinkClick(event) {
                    var targetId = $(this).data('targetId'),
                            target = $(this);

                    tabsLinks.removeClass(activeClass);
                    target.addClass(activeClass);

                    tabsBlocks
                            .removeClass(activeClass)
                            .filter('[data-target-id="' + targetId + '"]')
                            .addClass(activeClass);


                    if (window.matchMedia("(max-width: 1024px)").matches) {
                        $('.tabs__link.active').after(tabsContents);
                        var scrollBlock = $('.tabs__link.active').offset().top,
                                fixedTop = $('.mobile-header').outerHeight();
                        $('html, body').animate({scrollTop: scrollBlock - fixedTop + 2}, 700);
                    }
                    ;
                }
                ;
                $(window).on('resize', function () {
                    if (window.matchMedia("(max-width: 1024px)").matches) {
                        if (!flag) {
                            $('.tabs__link.active').after(tabsContents);

                            flag = true;
                        }
                    } else {
                        if (flag) {
                            $('.tabs__links').after(tabsContents);
                            var maxWidth = $('.tabs__links').offset().left + $('.tabs__links').width();

                            tabsLinks.each(function () {
                                var position = $(this).offset().left;
                                if (position >= maxWidth) {
                                    $(this).addClass('inactive');
                                }
                            });

                            flag = false;
                        }
                    }
                }).trigger('resize');

            });
        };
        $(function () {
            $('.tabs').myTabs();
        });

        $("#a_like, #a_dislike").click(function () {
            window.event.preventDefault();
            var id = $("#i_like, #i_dislike").data('options');
            var options = $(this).attr('id');
            var objC = $(this);


            $.ajax({
                url: '/ajax/likereview/',
                data: 'options=' + options + '&id=' + id,
                type: "POST",
                success: function (datdczv12ea) {
                    objC.find('i').css({'color': '#CC6633'});
                    show_popup_result(datdczv12ea);
                }
            });
        });

        $(document).on('click touchstart', '#showMenu', function () {
            var checked = jQuery("#nav-toggle").is(':checked');
            console.log(checked);
            if (checked) {
                $("#nav-toggle").prop('checked', false).removeAttr('checked');
            } else {
                $("#nav-toggle").prop('checked', true).attr('checked', 'checked');
            }
        }
        );
    })(jQuery, window);

//---------------------------------
    jQuery("a.showPicture").colorbox({height: "90%"});
    jQuery(".showVideo").colorbox({iframe: true, width: "80%", height: "80%"});

    jQuery(".htmlForm").colorbox
            ({
                inline: true, width: "40%",
                onOpen: function () {
                    switch ($(this).attr("id")) {
                        case "bKaluzCall":
                            document.getElementById('serviceAddressCall').options[1].selected = 'true';
                            break;
                        case "bNigegrCall":
                            document.getElementById('serviceAddressCall').options[2].selected = 'true';
                            break;
                        case "bLobnensCall":
                            document.getElementById('serviceAddressCall').options[3].selected = 'true';
                            break;
						case "bSevCall":
                            document.getElementById('serviceAddressCall').options[4].selected = 'true';
                            break;
                    }
                }
            });
    $(".group1").colorbox({rel: 'group1'});
    
    jQuery("#show_all_models").on('click', function() {
        let button = $(this);
        let parentBlock = button.closest(".step-slider");
        let slideBlock = parentBlock.find(".owl-stage");
        let navigation = parentBlock.find(".owl-nav");
        
        if(button.hasClass("opened")) {            
            button.removeClass("opened");
            button.text("Показать все");
            slideBlock.removeClass("open_slider"); 
            navigation.css("display", "contents");
        } else {            
            button.addClass("opened");
            button.text("Скрыть");
            let images = slideBlock.find("img.owl-lazy");        
            jQuery.each(images, function(idx, image) {
                let imgObj = jQuery(image);
                imgObj.attr("src", imgObj.data("src") );
                imgObj.css("opacity", "1");
            });
            slideBlock.addClass("open_slider");
            navigation.css("display", "none");
        }       

    });
    
    
    
});

$('.phoneInput').mask('+7 (999) 999-9999');
/*$('.dateInput').mask("99.99.9999");
 
 jQuery(".date").each(function () {
 jQuery(this).ionDatePicker();
 });*/

function show_popup_result(result_str) {
    $("#done_result").html(result_str);
    let popup_done = $('[data-remodal-id=popup-done]').remodal();
    popup_done.open();
}

///отображение видео анатомия авто
//отображение видео анатомия авто
/**
 * Функция вставляет html код в элемент с id равным element_id
 
 **/
function loadContent(element_id) {
    function proverka_url(url) {
        document.getElementById(element_id).innerHTML = '\
				<p><a  onclick="loadContent(\'' + element_id + '\'),showFun(\'' + element_id + '\')"><img src="/images/video/close.png" width="50" height="50" alt="x" style="border:0px;cursor: pointer;"/></a></p>\
				<div id="blok_model"  class="zagruzchik"  width="468" height="600" ></div>';
        $.ajax({
            data: 'url=' + url,
            url: '/images/video/video_oll.php',
            type: 'POST',
            success: function (data) {
                if (data != 1) {
                    $('#blok_model').html('\
			<iframe width="468" height="600" src="/images/video/video.php?id=' + element_id + '"></iframe>');
                }//здесь надо изменить на == сейчас сделано для теста как будто нет видео
                else
                {
                    $('#blok_model').html('<iframe   width="468" height="600"  src=\
				"' + url + '"\
				></iframe>');
                }
            },
            error: function (xhr, str) {
                //alert('Возникла ошибка: ' + xhr.responseCode);
            }
        });
    }//proverka_url		

    //Если элемент с id-шником element_id существует 
    if (document.getElementById(element_id)) {
        //https://www.tokyogarage.ru/transmission/
        if (element_id == 'akpp') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=6zvj5y41cgvq';
            proverka_url(url);
        }
        //https://www.tokyogarage.ru/tech_to/
        if (element_id == 'to') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=won854g4r60h';
            proverka_url(url);
        }
        if (element_id == 'zamena_masla')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=sdwgssrbamjn"></iframe>';
        //https://www.tokyogarage.ru/engine_repair/
        if (element_id == 'dvigatel') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=v23bnclzmtwm';
            proverka_url(url);
        }
        //https://www.tokyogarage.ru/diesel/
        if (element_id == 'disdvigatel') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=4q8ij23solx8';
            proverka_url(url);
        }
        //
        if (element_id == 'kusov') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=b32hwlkfigo8';
            proverka_url(url);
        }
        //
        if (element_id == 'kpp') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=405d8mwbkxcl';
            proverka_url(url);
        }

        if (element_id == 'grm')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=wpuappy4xivx"></iframe>';
        if (element_id == 'starter')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=8cn5dk0qzda6"></iframe>';
        //https://www.tokyogarage.ru/aircondition/
        if (element_id == 'kondei')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=7f24k2bf5i0s"></iframe>';
        //https://www.tokyogarage.ru/repair_suspension/
        if (element_id == 'podveska') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=zd40w07g9ygh';
            proverka_url(url);
        }

        //https://www.tokyogarage.ru/electronica/
        if (element_id == 'electrika') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=mg0xmgwekqlk';
            proverka_url(url);
        }
        //
        if (element_id == 'benso_nasos')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=13xrtdok8zzh"></iframe>';
        //
        if (element_id == 'vipusk')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480" src="https://ru.vehiclevisuals.com/animation_link.php?num=9z25wdgjndwu"></iframe>';
        //https://www.tokyogarage.ru/repair_suspension/steering/
        if (element_id == 'rulj') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=6ypmfya15176';
            proverka_url(url);
        }
        //https://www.tokyogarage.ru/engine_repair/ingektor/
        if (element_id == 'toplivo') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=stwp4jdatyt0';
            proverka_url(url);
        }

        //https://www.tokyogarage.ru/repair_suspension/breaking/ 
        if (element_id == 'tormoz') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=6z4s41rb4jst';
            proverka_url(url);
        }
        //
        //рулевое управление
        if (element_id == 'sxod_rasval')
            document.getElementById(element_id).innerHTML = '<iframe class="col-md-12" height="480"  src="https://ru.vehiclevisuals.com/animation_link.php?num=j4103wr8rm36"></iframe>';

        //
        if (element_id == 'oxlagdenie') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=3nnji1qyah5d';
            proverka_url(url);
        }
        //
        if (element_id == 'sceplenie') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=f5hfpi07cfx8';
            proverka_url(url);
        }
        //
        //оси и приводы задний редуктор
        if (element_id == 'zad_reduktor') {
            var url = 'https://ru.vehiclevisuals.com/animation_link.php?num=4fip3ps3s5cf';
            proverka_url(url);
        }
    }
}

//показываем блок  
var visible = false;
function showFun(element_id) {
    if (visible) {
        document.getElementById(element_id).style.display = 'none';
        document.getElementById(element_id).innerHTML = "";
        visible = false;
    } else {
        document.getElementById(element_id).style.display = 'block';
        visible = true;
    }
}

//-----------------------------живой поиск в хедере	---------------------------------
//$(document).ready(function(){
$('#qSearchMob').keyup(function () {
    q = this.value;
    console.log(q);
    get_result();

});

$('#qSearch').keyup(function () {
    q = this.value;
    console.log(q);
    get_result2();

});

$('#search_result').on('mouseleave', '.live-search', function () {
    $('#search_result').find('.live-search').remove();
});

$('#search_result_desc').on('mouseleave', '.live-search', function () {
    $('#search_result_desc').find('.live-search').remove();
});

function get_result() {
    //очищаем результаты поиска
    $('#search_result').html('');
    //пока не получили результаты поиска - отобразим прелоадер
    $('#search_result').append('<div></div>');
    $.ajax({
        type: "POST",
        url: "/search/ajax_search.php",
        data: "q=" + q,
        dataType: 'json',
        success: function (json) {

            //очистим прелоадер
            $('#search_result').html('');
            $('#search_result').append('<div class="live-search"></div>');

            if (jQuery.isEmptyObject(json)) {
                $('#search_result').find('.live-search').append('<span class="errors">Ничего не найдено...</span>');
                return false;
            } else {
                //добавляем каждый элемент массива json внутрь div-ника с class="live-search" (вёрстку можете использовать свою)
                $.each(json, function (index, element) {

                    $('#search_result').find('.live-search').append('<a href="' + element.URL + '" class="live-search__item"><span class="live-search__item-inner"><span class="live-search__item-name"><span class="live-search__item-hl">' + element.TITLE + '</span></span></span></a>');

                    //console.log (element.BODY_FORMATED);
                });
            }
        }
    });
}

function get_result2() {
    //очищаем результаты поиска
    $('#search_result_desc').html('');
    //пока не получили результаты поиска - отобразим прелоадер
    $('#search_result_desc').append('<div></div>');
    $.ajax({
        type: "POST",
        url: "/search/ajax_search.php",
        data: "q=" + q,
        dataType: 'json',
        success: function (json) {

            //очистим прелоадер
            $('#search_result_desc').html('');
            $('#search_result_desc').append('<div class="live-search"></div>');

            if (jQuery.isEmptyObject(json)) {
                $('#search_result_desc').find('.live-search').append('<span class="errors">Ничего не найдено...</span>');
                return false;
            } else {
                //добавляем каждый элемент массива json внутрь div-ника с class="live-search" (вёрстку можете использовать свою)
                $.each(json, function (index, element) {

                    $('#search_result_desc').find('.live-search').append('<a href="' + element.URL + '" class="live-search__item"><span class="live-search__item-inner"><span class="live-search__item-name"><span class="live-search__item-hl">' + element.TITLE + '</span></span></span></a>');

                    //console.log (element.BODY_FORMATED);
                });
            }
        }
    });
}
//});
//-----------------------------живой поиск моделей------------------------------
$('#modelSearch').keyup(function () {
    modelSearch = this.value;
    show_models();
});

$("#modelSearch").bind('paste', function () {
    var self = this;
    setTimeout(function (e) {
        modelSearch = $(self).val();
        show_models();
    }, 0);
});

$('#modelSearch').bind('paste', function () {
    modelSearch = this.value;
    show_models();
});

$('#modelSearch').on('mouseleave', '.live-search2', function () {
    $('#modelSearch').find('.live-search2').remove();
});

function show_models() {
    var markid = $('#markId').val();

    $('#search_model').html('');
    //пока не получили результаты поиска - отобразим прелоадер
    $('#search_model').append('<div></div>');
    $.ajax({
        type: "POST",
        url: "/ajax/search/models/",
        data: "modelSearch=" + modelSearch + '&markid=' + markid,
        dataType: 'json',
        success: function (json) {
            //очистим прелоадер
            $('#search_model').html('');
            $('#search_model').append('<div class="live-search2"></div>');

            if (jQuery.isEmptyObject(json)) {
                $('#search_model').find('.live-search2').append('<span class="errors">Ничего не найдено...</span>');
                return false;
            } else {
                //добавляем каждый элемент массива json внутрь div-ника с class="live-search" (вёрстку можете использовать свою)
                $.each(json, function (index, element) {
                    $('#search_model').find('.live-search2').append('<a href="' + element.URL + '" class="live-search2__item"><span class="live-search2__item-inner"><span class="live-search2__item-name"><span class="live-search2__item-hl">' + element.TITLE + '</span></span></span></a>');
                    //console.log (element.BODY_FORMATED);
                });
            }
        }
    });
}

//---------------------------- живой поиск услуги для марок и моделей-----------------------

$('#sUslugaMarki').keyup(function () {
    sUslugaMarki = this.value;
    show_usluga_marki();
});

$("#sUslugaMarki").bind('paste', function () {
    var self = this;
    setTimeout(function (e) {
        sUslugaMarki = $(self).val();
        show_usluga_marki();
    }, 0);
});

$('#sUslugaMarki').on('mouseleave', '.live-search3', function () {
    $('#sUslugaMarki').find('.live-search3').remove();
});

function show_usluga_marki() {
    var mark = $('#s_marka').val();
    var model = $('#s_model').val();

    $('#search_uslude_makr').html('');
    //пока не получили результаты поиска - отобразим прелоадер
    $('#search_uslude_makr').append('<div></div>');
    $.ajax({
        type: "POST",
        url: "/ajax/search/service/",
        data: "sUslugaMarki=" + sUslugaMarki + '&mark=' + mark + '&model=' + model,
        dataType: 'json',
        success: function (json) {
            //очистим прелоадер
            $('#uslugi_marka').html('');
            $('#uslugi_marka').append('<div class="live-search3"></div>');
            //добавляем каждый элемент массива json внутрь div-ника с class="live-search" (вёрстку можете использовать свою)

            if (jQuery.isEmptyObject(json)) {
                $('#uslugi_marka').find('.live-search3').append('<span class="errors">Ничего не найдено...</span>');
                return false;
            } else {
                $.each(json, function (index, element) {
                    $('#uslugi_marka').find('.live-search3').append('<a href="' + element.URL + '" class="live-search3__item"><span class="live-search3__item-inner"><span class="live-search3__item-name"><span class="live-search3__item-hl">' + element.TITLE + '</span></span></span></a>');
                    //console.log (element.BODY_FORMATED);
                });
            }
        }
    });
}

//-------------------------живой поиск основных услуг---------------------------

$('#uslugaSearch').keyup(function () {
    uslugaSearch = this.value;
    show_usluga();
});

$("#uslugaSearch").bind('paste', function () {
    var self = this;
    setTimeout(function (e) {
        uslugaSearch = $(self).val();
        show_usluga();
    }, 0);
});

$('#uslugaSearch').on('mouseleave', '.live-search4', function () {
    $('#uslugaSearch').find('.live-search4').remove();
});

function show_usluga() {
    $('#search_usluga').html('');
    //пока не получили результаты поиска - отобразим прелоадер
    $('#search_usluga').append('<div></div>');
    $.ajax({
        type: "POST",
        url: "/ajax/search/mainservice/",
        data: "uslugaSearch=" + uslugaSearch,
        dataType: 'json',
        success: function (json) {
            //очистим прелоадер
            $('#search_usluga').html('');
            $('#search_usluga').append('<div class="live-search4"></div>');
            //добавляем каждый элемент массива json внутрь div-ника с class="live-search" (вёрстку можете использовать свою)

            if (jQuery.isEmptyObject(json)) {
                $('#search_usluga').find('.live-search4').append('<span class="errors">Ничего не найдено...</span>');
                return false;
            } else {
                $.each(json, function (index, element) {
                    $('#search_usluga').find('.live-search4').append('<a href="' + element.URL + '" class="live-search4__item"><span class="live-search4__item-inner"><span class="live-search4__item-name"><span class="live-search4__item-hl">' + element.TITLE + '</span></span></span></a>');
                    //console.log (element.BODY_FORMATED);
                });
            }
        }
    });
}

//------------------------форма обратной связи-------------------------

document.getElementById("bKaluz").onclick = function () {
    document.getElementById('serviceAddress').options[1].selected = 'true';
};

document.getElementById("bNigegr").onclick = function () {
    document.getElementById('serviceAddress').options[2].selected = 'true';
};

document.getElementById("bLobnens").onclick = function () {
    document.getElementById('serviceAddress').options[3].selected = 'true';
};
//document.getElementById("sendRequestCall").onclick = function(){
// 	кол-во символов 
$('#textCall').on("keydown", function (e) {
    document.getElementById('countSymbolZayavka').innerHTML = "Максимальное кол-во символов 500. Введенно: " + this.value.length;
});

$("#textCall").bind('paste', function () {
    setTimeout(function (e) {
        document.getElementById('countSymbolZayavka').innerHTML = "Максимальное кол-во символов 500. Введенно: " + $("#textCall").val().length;
    }, 0);
});

function sendCallRequest() {
    window.event.preventDefault();
    var service = 0;
    var re = /(http)|(www)|(https)/;
    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    var tags = /<[^>]+>/;
    var nameCall = document.getElementById('nameCall').value;
    var phoneCall = document.getElementById('phoneCall').value;

    //var emailCall = document.getElementById('emailCall').value;
    //var textCall = document.getElementById('textCall').value;
    if (document.getElementById('tip_zaiavki') === null || document.getElementById('tip_zaiavki').value == undefined) {
        var tip_zaiavki = 'tip_zaiavki';
    } else
        var tip_zaiavki = document.getElementById('tip_zaiavki').value;

    var select = document.getElementById('serviceAddress');
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.selected) {
            service = option.value;
        }
    }
    //service = document.getElementById("serviceAddress").value();
    console.log(service);

    if (service > 0 && service <= 4) {
        //var captcha = grecaptcha.getResponse(callback);

        if (nameCall == '') {
            alert('Заполните поле Имя!');
        }//+service
        else if (/[0-9]/.test(nameCall)) {
            alert('В поле Имя не могут содержаться цифры!');
        } else if (/[a-zA-Z]/.test(nameCall)) {
            alert('В поле Имя не могут английские буквы!');
        } else if (/[a-zA-Z]/.test(nameCall)) {
            alert('В поле Имя не могут английские буквы!');
        } else if (phoneCall == '') {
            alert('Заполните поле Телефон!');
        }
        //else if(emailCall && (!pattern.test(emailCall))){alert('Проверьте е-майл');	} 
        //else if(textCall.length>500){alert('Превышена длина текста в поле Комментарий!');}
        //else if(textCall.search(re) != -1){alert('Поле Комментарий не должно содержать ссылок!!');}
        //else if(textCall.search(tags) != -1){alert('Поле Комментарий не должно содержать тегов!');}					
        //else if(!$("#obrabotkaDanixZayavka").is(":checked")){alert('Вы не дали согласие на обработку персональных данных!');}	
        //else if(!captcha.length){alert('Вы не прошли проверку "Я не робот"');}
        else {
            let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
            let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
            let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
            //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
            let phone_number = phoneCall.replace(/\D+/g, "");
            let code_group = '389262'; // lobnenskaya
            if (service === '1') { // kalugskaya
                if(klg === '74994951237')
                    code_group = '298080';
                else if(klg === '74992880537')
                    code_group = '389253';
                else if(klg === '74953747588')
                    code_group = '205839'; console.log("тут калуж");
            }else if(service === '2'){ // ydal
                if(ydal === '74992880684')
                    code_group = '298074';
                else if(ydal === '74996537059')
                    code_group = '389250';
                else if(ydal === '74953749918')
                    code_group = '205843';
            }else if(service === '3'){ // lobnenskaya
                if(lbn === '74993224193')
                    code_group = '298077';
                else if(lbn === '74996535974')
                    code_group = '389256';
                else if(lbn === '74994551821')
                    code_group = '389259';
                else if(lbn === '74951507037')
                    code_group = '205837';
                let url = window.location.href;
                let comtrans = false;
                let list_comtrans = ['/commercial/',
                    '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
                    '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
                ];
                for(let i=0;i<list_comtrans.length;i++){
                    if(url.indexOf(list_comtrans[i]) >= 0){
                        comtrans = true;
                        break;
                    }
                }
                if(comtrans)
                    code_group = '388986';
            }else if(service === '4'){ // sev
                code_group = '389262';
            }

            console.log("code_group " + code_group);
            console.log("service " + service);

            if (window.ComagicWidget) {
                const t = +new Date() + 10000;
                if(url_patch && list_comtrans_url.includes(url_patch)){
                    ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
                    console.log("Ком-транс");
                } else {
                    ComagicWidget.sitePhoneCall({phone: phone_number, group_id: code_group, delayed_call_time: t.toString()});
                    console.log(code_group);
                }
            }

            $('.icon-close').click();
            show_popup_result('Спасибо! Мы Вам скоро перезвоним!');

            $.ajax({
                type: "POST",
                url: '/ajax/sendform/',
                data: "nameCall=" + nameCall + '&phoneCall=' + phoneCall + '&service=' + service + '&tip_zaiavki=' + tip_zaiavki, //'&textCall='+textCall+'&emailCall='+emailCall+captcha='+captcha+'&
                success: function (data) {
                    yaCounter5921719.reachGoal('ostavit_zayavku');
                    $('#sendRequestCall').remove();
                    $('#phoneCall').html('Отправлено');
                }
            });
        }
    } else {
        alert('Вы не выбрали автосервис');
    }
}

//------------------------форма перезвони мне-------------------------		

$('#textCall2').on("keydown", function (e) {
    document.getElementById('countSymbolCall').innerHTML = "Максимальное кол-во символов 500. Введенно: " + this.value.length;
});

$("#textCall2").bind('paste', function () {
    setTimeout(function (e) {
        document.getElementById('countSymbolCall').innerHTML = "Максимальное кол-во символов 500. Введенно: " + $("#textCall2").val().length;
    }, 0);
});


//$('.sendCallBack').onclick(function(event){
function sendCall() {

    var re = /(http)|(www)|(https)/;
    var tags = /<[^>]+>/;
    window.event.preventDefault();
    var service = '';

    var phoneCall = document.getElementById('phoneCall2').value;
    //#var textCall = document.getElementById('textCall2').value;

    var select = document.getElementById('serviceAddressCall');
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.selected) {
            service = option.value;
        }
    }

    //var captcha = grecaptcha.getResponse(call);
    if (phoneCall == '') {
        alert('Заполните поле Телефон!');
    }
    //else if(textCall.length>500){alert('Превышена длина текста в поле Комментарий!');}
    //else if(textCall.search(re) != -1){alert('Поле Комментарий не должно содержать ссылок!');}	
    //else if(textCall.search(tags) != -1){alert('Поле Комментарий не должно содержать тегов!');}		
    //else if(!captcha.length){alert('Вы не прошли проверку "Я не робот"');}
    //else if(!$("#obrabotkaDanixCall").is(":checked")){alert('Вы не дали согласие на обработку персональных данных!');}	
    else {
        let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
        let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
        let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
        //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
        let code_group = '389262'; // lobnenskaya
        if (service === '1') { // kalugskaya
            if(klg === '74994951237')
                code_group = '298080';
            else if(klg === '74992880537')
                code_group = '389253';
            else if(klg === '74953747588')
                code_group = '205839'; console.log("тут калуж");
        }else if(service === '2'){ // ydal
            if(ydal === '74992880684')
                code_group = '298074';
            else if(ydal === '74996537059')
                code_group = '389250';
            else if(ydal === '74953749918')
                code_group = '205843';
        }else if(service === '3'){ // lobnenskaya
            if(lbn === '74993224193')
                code_group = '298077';
            else if(lbn === '74996535974')
                code_group = '389256';
            else if(lbn === '74994551821')
                code_group = '389259';
            else if(lbn === '74951507037')
                code_group = '205837';
            let url = window.location.href;
            let comtrans = false;
            let list_comtrans = ['/commercial/',
                '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
                '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
            ];
            for(let i=0;i<list_comtrans.length;i++){
                if(url.indexOf(list_comtrans[i]) >= 0){
                    comtrans = true;
                    break;
                }
            }
            if(comtrans)
                code_group = '388986';
        }else if(service === '4'){ // sev
            code_group = '389262';
        }

        if (window.ComagicWidget) {
            const t = +new Date() + 10000;
            if(url_patch && list_comtrans_url.includes(url_patch)){
                ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
            } else {
                ComagicWidget.sitePhoneCall({phone: phoneCall, group_id: code_group, delayed_call_time: t.toString()});
                console.log("code_group " + code_group);
            }
        }

        $('.icon-close').click();
        show_popup_result('Спасибо! Мы Вам скоро перезвоним!');

        $.ajax({
            type: "POST",
            url: '/ajax/sendcall/',
            data: '&phoneCall=' + phoneCall + '&service=' + service, //+'&captcha='+captcha
            success: function (data) {
                $('#sendCallBack').remove();
                $('#phoneCall2').html('Отправлено');
            }
        });
    }
}

//------------------------заявка на эвакуацию------------------------		


$('#textCall3').on("keydown", function (e) {
    document.getElementById('countSymbolEvac').innerHTML = "Максимальное кол-во символов 500. Введенно: " + this.value.length;
});

$("#textCall3").bind('paste', function () {
    setTimeout(function (e) {
        document.getElementById('countSymbolEvac').innerHTML = "Максимальное кол-во символов 500. Введенно: " + $("#textCall3").val().length;
    }, 0);
});

//$('.sendCallBack').onclick(function(event){
function sendCallEvacuation() {
    var re = /(http)|(www)|(https)/;
    var tags = /<[^>]+>/;
    window.event.preventDefault();
    var service = '';
    var phoneCall = document.getElementById('phoneCall3').value;
    var textCall = document.getElementById('textCall3').value;
    var select = document.getElementById('serviceAddressCall');
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.selected) {
            service = option.value;
        }
    }


    if (phoneCall == '') {
        alert('Заполните поле Телефон!');
    } else if (textCall.search(re) != -1) {
        alert('Поле Комментарии не должно содержать ссылок!');
    } else if (textCall.length > 500) {
        alert('Превышена длина текста поля Комментарии!');
    } else if (textCall.search(tags) != -1) {
        alert('Поле Комментарии не должно содержать тегов!');
    }
    //else if(!captcha.length){alert('Вы не прошли проверку "Я не робот"');}	
    else if (!$("#obrabotkaDanixEvac").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
    } else {
        let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
        let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
        let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
        //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
        let code_group = '389262'; // lobnenskaya
        if (service === '1') { // kalugskaya
            if(klg === '74994951237')
                code_group = '298080';
            else if(klg === '74992880537')
                code_group = '389253';
            else if(klg === '74953747588')
                code_group = '205839'; console.log("тут калуж");
        }else if(service === '2'){ // ydal
            if(ydal === '74992880684')
                code_group = '298074';
            else if(ydal === '74996537059')
                code_group = '389250';
            else if(ydal === '74953749918')
                code_group = '205843';
        }else if(service === '3'){ // lobnenskaya
            if(lbn === '74993224193')
                code_group = '298077';
            else if(lbn === '74996535974')
                code_group = '389256';
            else if(lbn === '74994551821')
                code_group = '389259';
            else if(lbn === '74951507037')
                code_group = '205837';
            let url = window.location.href;
            let comtrans = false;
            let list_comtrans = ['/commercial/',
                '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
                '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
            ];
            for(let i=0;i<list_comtrans.length;i++){
                if(url.indexOf(list_comtrans[i]) >= 0){
                    comtrans = true;
                    break;
                }
            }
            if(comtrans)
                code_group = '388986';
        }else if(service === '4'){ // sev
            code_group = '389262';
        }
        if (window.ComagicWidget) {
            const t = +new Date() + 10000;
            if(url_patch && list_comtrans_url.includes(url_patch)){
                ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
            } else {
                ComagicWidget.sitePhoneCall({phone: phoneCall, group_id: code_group, delayed_call_time: t.toString()});
                console.log("code_group " + code_group);
            }
        }

        $('.icon-close').click();
        show_popup_result('Спасибо! Мы Вам скоро перезвоним!');

        $.ajax({
            type: "POST",
            url: '/ajax/sendcall/',
            data: '&phoneCall=' + phoneCall + '&textCall=' + textCall + '&service=' + service + '&evacuation=1',
            success: function (data) {}
        });
    }
}

//------------------------отправка отщыва-------------------------


$('#textReview').on("keydown", function (e) {
    document.getElementById('countSymbol').innerHTML = "Максимальное кол-во символов 500. Введенно: " + this.value.length;
});

$("#textReview").bind('paste', function () {
    setTimeout(function (e) {
        document.getElementById('countSymbol').innerHTML = "Максимальное кол-во символов 500. Введенно: " + $("#textReview").val().length;
    }, 0);
});

function sendNewReview() {
    window.event.preventDefault();
    var re = /(http)|(www)|(https)/;
    var tags = /<[^>]+>/;
    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;

    var rating = 0;
    var nameReview = document.getElementById('nameReview').value;
    var phoneReview = document.getElementById('phoneReview').value;
    var emailReview = document.getElementById('emailReview').value;
    var titleReview = document.getElementById('titleReview').value;
    var textReview = document.getElementById('textReview').value;

    var select = document.getElementById('rating');
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.selected) {
            rating = option.value;
        }
    }

    var select = document.getElementById('avtoserviceOtziv');
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.selected) {
            avtoservice = option.value;
        }
    }

    if (rating != 0) {

        if (nameReview == '') {
            alert('Заполните поле Имя!');
        } else if (/[0-9]/.test(nameReview)) {
            alert('В поле Имя не могут содержаться цифры!');
        } else if (/[a-zA-Z]/.test(nameReview)) {
            alert('В поле Имя не могут английские буквы!');
        } else if (phoneReview == '') {
            alert('Введите телефон!');
        } else if (emailReview && (!pattern.test(emailReview))) {
            alert('Проверьте е-майл');
        } else if (avtoservice == '') {
            alert('Выберите автосервис!');
        } else if (titleReview == '') {
            alert('Введите заголовок отзыва!');
        } else if (textReview == '') {
            alert('Введите текст отзыва!');
        } else if (textReview.search(re) != -1) {
            alert('Поле Текст отзыва не должно содержать ссылок!!');
        } else if (textReview.search(tags) != -1) {
            alert('Поле Текст отзыва не должно содержать тегов!');
        } else if (textReview.length > 500) {
            alert('Превышена длина текста отзыва!');
        }
        //else if(!captcha.length){alert('Вы не прошли проверку "Я не робот"');}	 
        else if (!$("#obrabotkaDanix").is(":checked")) {
            alert('Вы не дали согласие на обработку персональных данных!');
        } else {

            // if (window.ComagicWidget) {
            //     ComagicWidget.sitePhoneCall({phone: phoneReview});
            // }

            if (window.ComagicWidget) {
                var t = +new Date() + 10000;
                let phone_number = phoneReview.replace(/\D+/g, "");

                var settings = {
                    "url": "https://api.qrenta.ru/sitephone/code_perezvon?phone=78005&site=5&name=Forma&phone_client=" + phone_number,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "x-api-key": "de78fc74-4c5b-4412-aa0a-ffefbfb6d0db"
                    },
                };
                $.ajax(settings).done(function (response) {
                    var id_ploshadki = "205839"; // По дефолту ставим 205839, если код не получен по какой либо причине
                    if (response['success']) {
                        id_ploshadki = response['data']['code_perezvon'];
                    }
                    if(url_patch && list_comtrans_url.includes(url_patch)){
                        ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
                    } else {
                        ComagicWidget.sitePhoneCall({phone: phone_number, group_id: id_ploshadki, delayed_call_time: t.toString()});
                    }
                });
            }

            $('.icon-close').click();
            show_popup_result('Спасибо! Ваш отзыв принят!');

            $.ajax({
                type: "POST",
                url: '/ajax/feedback/',
                data: "nameReview=" + nameReview + '&phoneReview=' + phoneReview + '&emailReview=' + emailReview + '&titleReview=' + titleReview + '&textReview=' + textReview + '&rating=' + rating + '&avtoservice=' + avtoservice, //+'&captcha='+captcha
                success: function (data) {}
            });
        }
    } else {
        alert('Пожалуста,  поставьте Вашу оценку');
    }
}

//----------------------- безналичная оплата в футере---------------

$('#ggoooo_payyy').click(function (e) {
    var valss99928 = $(this).parent().find('select[name=select_services_to_payss]').val();

    if (valss99928 == 'pay_naychnnn' || valss99928 == 'pay_shmiitttt') {
        //var captcha = grecaptcha.getResponse(payment);
        /*if (!captcha.length) {
         e.preventDefault();
         alert('Вы не прошли проверку "Я не робот"!');
         } 
         else{*/
        if (valss99928 == 'pay_naychnnn') {
            $(this).parent().find('a#ggoooo_payyy').attr('target', '_blank').attr('href', 'https://pay1.tokyogarage.ru/');
        } else if (valss99928 == 'pay_shmiitttt') {
            $(this).parent().find('a#ggoooo_payyy').attr('target', '_blank').attr('href', 'https://pay2.tokyogarage.ru/');
        }
        /*}*/
    } else {
        e.preventDefault();
        alert('Выберите сервис');
    }
});

/*------------------------галлерея------------------------*/
$(document).ready(function () {

    jQuery.colorbox.settings.maxWidth = '97%';//ставим свои значения
    jQuery.colorbox.settings.maxHeight = '97%';//ставим свои значения

    $('span.img_prewtokio').on('click', 'img', function () {
        var img_name = $(this).attr('src').replace('s.jpg', 'b.jpg');
        $(this).colorbox({
            href: img_name,
            opacity: "0.30"
        });
    });
});

/*--------------------- Проверка формы оценки по фото-------------------------*/
function checkEstimateForm() {

    var user_name = document.getElementById('user_name').value;
    var user_phone = document.getElementById('user_phone').value;
    var user_email = document.getElementById('user_email').value;
    var select_service = document.getElementById('select_service').value;
    var message = document.getElementById('message').value;
    console.log('select_service => ' + select_service);
    if (user_name == "") {
        alert("Не указано имя!");
        return false;
    }
    if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    }
    if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    }
    if (user_phone == '') {
        alert('Поле телефон не заполнено!');
        return false;
    }
    if (user_email != '') {
        var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
        if (!pattern.test(user_email)) {
            alert('Проверьте е-майл');
            return false;
        }
    }
    if (!$("#ocenkaPhotoCoglaciya").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }

    let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
    let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
    let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
    //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
    let code_group = '389262'; // lobnenskaya
    if (select_service === '1') { // kalugskaya
        if(klg === '74994951237')
            code_group = '298080';
        else if(klg === '74992880537')
            code_group = '389253';
        else if(klg === '74953747588')
            code_group = '205839'; console.log("тут калуж");
    }else if(select_service === '2'){ // ydal
        if(ydal === '74992880684')
            code_group = '298074';
        else if(ydal === '74996537059')
            code_group = '389250';
        else if(ydal === '74953749918')
            code_group = '205843';
    }else if(select_service === '3'){ // lobnenskaya
        if(lbn === '74993224193')
            code_group = '298077';
        else if(lbn === '74996535974')
            code_group = '389256';
        else if(lbn === '74994551821')
            code_group = '389259';
        else if(lbn === '74951507037')
            code_group = '205837';
        let url = window.location.href;
        let comtrans = false;
        let list_comtrans = ['/commercial/',
            '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
            '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
        ];
        for(let i=0;i<list_comtrans.length;i++){
            if(url.indexOf(list_comtrans[i]) >= 0){
                comtrans = true;
                break;
            }
        }
        if(comtrans)
            code_group = '388986';
    }else if(select_service === '4'){ // sev
        code_group = '389262';
    }
    if (window.ComagicWidget) {
        const t = +new Date() + 10000;
        if(url_patch && list_comtrans_url.includes(url_patch)){
            ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
        } else {
            ComagicWidget.sitePhoneCall({phone: user_phone, group_id: code_group, delayed_call_time: t.toString()});
            console.log("code_group " + code_group);
        }
    }

    //alert('Спасибо! Ваша заявка принята.');
    setTimeout("show_popup_result('Спасибо! Ваша заявка принята.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

/*------------------ Проверка формы на странице service ----------------------*/
function checkFormService(form) {
    var user_name = document.getElementById('user_name').value;
    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    if (user_name == "") {
        alert("Не указано имя!");
        return false;
    }
    if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    }
    if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    }
    if ((document.getElementById('user_email').value) && (!pattern.test(document.getElementById('user_email').value))) {
        alert('Проверьте е-майл');
        return false;
    }
    if (document.getElementById('user_phone').value == "") {
        alert("Не указан телефон!");
        return false;
    }
    var selection = '';
    var mSel = document.getElementById('select_service_form');
    if (mSel.selectedIndex != -1) {
        selection = mSel.options[mSel.selectedIndex].value;
    }
    if (selection == '') {
        alert("Не выбран автосервис!");
        return false;
    }
    if (!$("#obrabotkaDanixService").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }
    // if (window.ComagicWidget) {
    //     ComagicWidget.sitePhoneCall({phone: document.getElementById('user_phone').value});
    // }

    if (window.ComagicWidget) {
        var t = +new Date() + 10000;
        let phone_number = (document.getElementById('user_phone').value).replace(/\D+/g, "");

        var settings = {
            "url": "https://api.qrenta.ru/sitephone/code_perezvon?phone=78005&site=5&name=Forma&phone_client=" + phone_number,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "x-api-key": "de78fc74-4c5b-4412-aa0a-ffefbfb6d0db"
            },
        };
        $.ajax(settings).done(function (response) {
            var id_ploshadki = "205839"; // По дефолту ставим 205839, если код не получен по какой либо причине
            if (response['success']) {
                id_ploshadki = response['data']['code_perezvon'];
            }
            if(url_patch && list_comtrans_url.includes(url_patch)){
                ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
            } else {
                ComagicWidget.sitePhoneCall({phone: phone_number, group_id: id_ploshadki, delayed_call_time: t.toString()});
            }
        });
    }

    //alert('Спасибо! Ваша заявка принята.');
    setTimeout("show_popup_result('Спасибо! Ваша заявка принята.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

/*------------- Проверка и отправка формы на странице вакансии ---------------*/
function checkSendFormVacancy(event) {
    
    

    let form = $("#vacancy_form");
   
    let pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    let nameSender = form.find('input[name=nameSender]').val();
    let phoneSender = form.find('input[name=phoneSender]').val();
    // let emailSender = form.find('input[name=emailSender]').val();
    // let urlresume = form.find('input[name=urlresume]').val();
    // let fileresume = form.find('input[name=fileresume]').val();
    
    if (nameSender == '') {
        alert('Поле Имя не заполнено!');
        return false;
    } else if (/[0-9]/.test(nameSender)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    } else if (/[a-zA-Z]/.test(nameSender)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    } else if (phoneSender == '') {
        alert('Поле телефон не заполнено!');
        return false;
    }
    // else if (emailSender == '') {
    //     alert('Поле электронный адрес не заполнено');
    //     return false;
    // } else if (!pattern.test(emailSender)) {
    //     alert('Проверьте е-майл');
    //     return false;
    // } else if ((fileresume == '') && (urlresume == '')) {
    //     alert('Прикрепите ссылку на резюме или отправьте резюме вложением');
    //     return false;
    // }
    else if (!$("#obrabotkaVacancy").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }
    
    let data = new FormData(document.getElementById('vacancy_form'));
    /*data.append('nameSender', nameSender);
    data.append('phoneSender', phoneSender);
    data.append('emailSender', emailSender);
    data.append('urlresume', urlresume);
    if (fileresume) {
        data.append('fileresume', fileresume.files[0]);
    }    */
    
    $.ajax({
        url: '/ajax/vacancysend/',
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
            if (typeof respond.error === 'undefined') {
                show_popup_result('Спасибо! Ваш отклик на вакансию принят.');
            } else {
                show_popup_result('ОШИБКА: ' + respond.data);
            }
        },
        error: function (jqXHR, status, errorThrown) {
            show_popup_result('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
    });   
    
    //alert('Спасибо! Ваш отклик на вакансию принят.');
    //setTimeout("show_popup_result('Спасибо! Ваш отклик на вакансию принят.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return false;
}

function checkSendFormReport(event) {
    let form = $("#report_form");
    let pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    let nameSender = form.find('input[name=nameSender]').val();
    let phoneSender = form.find('input[name=phoneSender]').val();
    let emailSender = form.find('input[name=emailSender]').val();
    let srochnoSender = form.find('input[name=urgently_report]').val();
    let text = form.find('textarea[name=text_report]').val();
    let selectService = 1;
    if(document.getElementById('serviceAddress').options[1].selected)
        selectService = 1;
    if(document.getElementById('serviceAddress').options[2].selected)
        selectService = 2;
    if(document.getElementById('serviceAddress').options[3].selected)
        selectService = 3;
    if(document.getElementById('serviceAddress').options[4].selected)
        selectService = 4;
    if(nameSender.length>0){
        if (/[0-9]/.test(nameSender)) {
            alert('В поле Имя не могут содержаться цифры!');
            return false;
        } else if (/[a-zA-Z]/.test(nameSender)) {
            alert('В поле Имя не могут английские буквы!');
            return false;
        }
    }
    if (phoneSender == '') {
        alert('Поле телефон не заполнено!');
        return false;
    }
    if(emailSender.length>0){
        if (!pattern.test(emailSender)) {
            alert('Проверьте е-майл');
            return false;
        }
    }
    let data = new FormData(document.getElementById('report_form'));
    data.append('selectService', selectService);
    $.ajax({
        url: '/ajax/reportsend/',
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
            if (typeof respond.error === 'undefined') {
                show_popup_result('Спасибо! Ваша жалоба принята.');
            } else {
                show_popup_result('ОШИБКА: ' + respond.data);
            }
        },
        error: function (jqXHR, status, errorThrown) {
            show_popup_result('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
    });
    return false;
}

/*----------------------- Проверка формы для вопросов ------------------------*/
function checkFormQuestion() {

    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    var user_name = document.getElementById('user_name').value;
    var user_email = document.getElementById('user_email').value;
    var question_theme = document.getElementById('question_theme').value;
    var message = document.getElementById('message').value;

    if (user_name == '') {
        alert('Поле "имя" не заполнено!');
        return false;
    } else if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    } else if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    } else if (question_theme == '') {
        alert('Поле Тема вопроса не заполнено!');
        return false;
    } else if (message == '') {
        alert('Поле Вопрос не заполнено!');
        return false;
    } else if (user_email == '') {
        alert('Поле е-майл не заполнено!');
        return false;
    } else if (!pattern.test(user_email)) {
        alert('Проверьте е-майл!');
        return false;
    } else if (!$("#obrabotkaDanix").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }
    //alert('Спасибо! Ваш вопрос принят.');
    setTimeout("show_popup_result('Спасибо! Ваш вопрос принят.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

/*-------------------- Проверка формы заявки не ремонт -----------------------*/
function checkRepairForm() {

    var user_name = document.getElementById('user_name').value;
    var user_phone = document.getElementById('user_phone').value;
    var user_date = document.getElementById('user_date').value;

    if (user_name == '') {
        alert('Поле "Имя" не заполнено!');
        return false;
    } else if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    } else if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    } else if (user_phone == '') {
        alert('Поле телефон не заполнено!');
        return false;
    } else if (user_date == '') {
        alert('Не указана дата призеда!');
        return false;
    } else if (!$("#obrabotkaDanix").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }

    let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
    let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
    let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
    //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
    let code_group = '389262'; // lobnenskaya
    if (select_service === '1') { // kalugskaya
        if(klg === '74994951237')
            code_group = '298080';
        else if(klg === '74992880537')
            code_group = '389253';
        else if(klg === '74953747588')
            code_group = '205839'; console.log("тут калуж");
    }else if(select_service === '2'){ // ydal
        if(ydal === '74992880684')
            code_group = '298074';
        else if(ydal === '74996537059')
            code_group = '389250';
        else if(ydal === '74953749918')
            code_group = '205843';
    }else if(select_service === '3'){ // lobnenskaya
        if(lbn === '74993224193')
            code_group = '298077';
        else if(lbn === '74996535974')
            code_group = '389256';
        else if(lbn === '74994551821')
            code_group = '389259';
        else if(lbn === '74951507037')
            code_group = '205837';
        let url = window.location.href;
        let comtrans = false;
        let list_comtrans = ['/commercial/',
            '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
            '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
        ];
        for(let i=0;i<list_comtrans.length;i++){
            if(url.indexOf(list_comtrans[i]) >= 0){
                comtrans = true;
                break;
            }
        }
        if(comtrans)
            code_group = '388986';
    }else if(select_service === '4'){ // sev
        code_group = '389262';
    }
    if (window.ComagicWidget) {
        const t = +new Date() + 10000;
        if(url_patch && list_comtrans_url.includes(url_patch)){
            ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
        } else {
            ComagicWidget.sitePhoneCall({phone: user_phone, group_id: code_group, delayed_call_time: t.toString()});
            console.log("code_group " + code_group);
        }
    }

    //alert('Спасибо! Ваша заявка принята.');
    setTimeout("show_popup_result('Спасибо! Ваша заявка принята.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

/*------------------- Проверка формы заявка на запчасти ----------------------*/
function checkPartsForm() {

    var user_name = document.getElementById('user_name').value;
    var user_phone = document.getElementById('user_phone').value;
    var user_marka = document.getElementById('user_marka').value;
    var user_vin = document.getElementById('user_vin').value;
    var name_zapchasti = document.getElementById('name_zapchasti').value;



    if (user_name == '') {
        alert('Поле `имя` не заполнено!');
        return false;
    } else if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    } else if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    } else if (user_phone == '') {
        alert('Поле телефон не заполнено!');
        return false;
    } else if (user_marka == '') {
        alert('Поле марка не заполнено!');
        return false;
    } else if (user_vin == '') {
        alert('Поле VIN не заполнено!');
        return false;
    } else if (name_zapchasti == '') {
        alert('Не указаны запчасти!');
        return false;
    } else if (!$("#obrabotkaDanix").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }

    let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
    let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
    let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
    //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
    let code_group = '389262'; // lobnenskaya
    if (select_service === '1') { // kalugskaya
        if(klg === '74994951237')
            code_group = '298080';
        else if(klg === '74992880537')
            code_group = '389253';
        else if(klg === '74953747588')
            code_group = '205839'; console.log("тут калуж");
    }else if(select_service === '2'){ // ydal
        if(ydal === '74992880684')
            code_group = '298074';
        else if(ydal === '74996537059')
            code_group = '389250';
        else if(ydal === '74953749918')
            code_group = '205843';
    }else if(select_service === '3'){ // lobnenskaya
        if(lbn === '74993224193')
            code_group = '298077';
        else if(lbn === '74996535974')
            code_group = '389256';
        else if(lbn === '74994551821')
            code_group = '389259';
        else if(lbn === '74951507037')
            code_group = '205837';
        let url = window.location.href;
        let comtrans = false;
        let list_comtrans = ['/commercial/',
            '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
            '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
        ];
        for(let i=0;i<list_comtrans.length;i++){
            if(url.indexOf(list_comtrans[i]) >= 0){
                comtrans = true;
                break;
            }
        }
        if(comtrans)
            code_group = '388986';
    }else if(select_service === '4'){ // sev
        code_group = '389262';
    }
    if (window.ComagicWidget) {
        const t = +new Date() + 10000;
        if(url_patch && list_comtrans_url.includes(url_patch)){
            ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
        } else {
            ComagicWidget.sitePhoneCall({phone: user_phone, group_id: code_group, delayed_call_time: t.toString()});
            console.log("code_group " + code_group);
        }
    }

    //alert('Спасибо! Ваша заявка принята.');
    setTimeout("show_popup_result('Спасибо! Ваша заявка принята.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

/*---------------------- Проверка формы универсальная ------------------------*/
function checkForm() {

    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
    var user_name = document.getElementById('user_name').value;
    var user_phone = document.getElementById('user_phone').value;
    var user_email = document.getElementById('user_email').value;
    var select_service = document.getElementById('select_service').value;
    console.log('select_service => ' + select_service);

    if (!grecaptcha.getResponse()) {
        alert('Вы не заполнили поле Я не робот!');
        return false; // возвращаем false и предотвращаем отправку формы
    } else if (user_name == '') {
        alert('Поле `Имя` не заполнено!');
        return false;
    } else if (/[0-9]/.test(user_name)) {
        alert('В поле Имя не могут содержаться цифры!');
        return false;
    } else if (/[a-zA-Z]/.test(user_name)) {
        alert('В поле Имя не могут английские буквы!');
        return false;
    } else if (user_phone == '') {
        alert('Поле телефон не заполнено!');
        return false;
    } else if (user_email != '') {
        var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/;
        if (!pattern.test(user_email)) {
            alert('Проверьте е-майл');
            return false;
        }
    } else if (!$("#obrabotkaDanix").is(":checked")) {
        alert('Вы не дали согласие на обработку персональных данных!');
        return false;
    }

    let lbn = $('#lbn').html().replace(/[^\d]/g, ''); // lobnenskaya
    let ydal = $('#ydal').html().replace(/[^\d]/g, ''); // ydal
    let klg = $('#klg').html().replace(/[^\d]/g, ''); // kalugskaya
    //let sev = $('#sev').html().replace(/[^\d]/g, ''); // sev
    let code_group = '389262'; // lobnenskaya
    if (select_service === '1') { // kalugskaya
        if(klg === '74994951237')
            code_group = '298080';
        else if(klg === '74992880537')
            code_group = '389253';
        else if(klg === '74953747588')
            code_group = '205839'; console.log("тут калуж");
    }else if(select_service === '2'){ // ydal
        if(ydal === '74992880684')
            code_group = '298074';
        else if(ydal === '74996537059')
            code_group = '389250';
        else if(ydal === '74953749918')
            code_group = '205843';
    }else if(select_service === '3'){ // lobnenskaya
        if(lbn === '74993224193')
            code_group = '298077';
        else if(lbn === '74996535974')
            code_group = '389256';
        else if(lbn === '74994551821')
            code_group = '389259';
        else if(lbn === '74951507037')
            code_group = '205837';
        let url = window.location.href;
        let comtrans = false;
        let list_comtrans = ['/commercial/',
            '/town_ace/','/alphard/','/serena/','/vanette/','/odyssey/','/libero/','/sambar/','/stepwgn/','/carnival/',
            '/h1/','/porter/','/hyundai_hd/','/h_starex/','/daihatsu_hijet/','/elysion/','/elgrand/'
        ];
        for(let i=0;i<list_comtrans.length;i++){
            if(url.indexOf(list_comtrans[i]) >= 0){
                comtrans = true;
                break;
            }
        }
        if(comtrans)
            code_group = '388986';
    }else if(select_service === '4'){ // sev
        code_group = '389262';
    }
    if (window.ComagicWidget) {
        const t = +new Date() + 10000;
        if(url_patch && list_comtrans_url.includes(url_patch)){
            ComagicWidget.sitePhoneCall({phone: phone_number, group_id: 388986, delayed_call_time: t.toString()});
        } else {
            ComagicWidget.sitePhoneCall({phone: user_phone, group_id: code_group, delayed_call_time: t.toString()});
            console.log("code_group " + code_group);
        }
    }

    //alert('Спасибо! Ваша заявка принята.');
    setTimeout("show_popup_result('Спасибо! Ваша заявка принята.<br><b>Страница будет перезагружена автоматически.</b>')", 2000);
    return true;
}

$(document).ready(function () {
    $('#slideDownSevice').on('click', function () {
        $(".collapseService").slideToggle('slow', function() {
            if ($(this).is(':visible'))
                $(this).css('display','flex');
        });
    });

    $('.engine_repair_gallery').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});